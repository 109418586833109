import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import List from '../components/Common/List'
import Typography from '../components/Common/Typography'
import YoutubeAccessForm from '../components/contact-form/YoutubeAccessForm'

import * as s from '../pages/news.module.scss'

const ToolsTurbochargeWorkshop = () => {
  const [modal, setModal] = React.useState(false)
  const images = useStaticQuery(imagesQuery)
  return (
    <>
      <SEO
        title="SIGNAX TOOLS: Turbocharge your BIM Projects. Workshop recording"
        description="This workshop designed to enhance productivity and collaboration for all project participants. We delve into the unique features and practical applications that streamline workflows for design teams, boost efficiency for contractor teams, support consultants, and offer significant benefits for owner/customer teams. Discover how SIGNAX solutions can transform your project management processes, from model validation and clash detection to budget tracking and data visualization."
      />
      <NewsSectionBlock date="August 16, 2024">
        <Typography variant="h1" color="blue">
          SIGNAX TOOLS: Turbocharge your BIM Projects
        </Typography>
        <Typography variant="body1">
          We are excited to share the recording and recap of our recent
          workshop, where we explored SIGNAX TOOLS. This workshop designed to
          enhance productivity and collaboration for all project participants.
          In this workshop, we delve into the unique features and practical
          applications that streamline workflows for design teams, boost
          efficiency for contractor teams, support consultants, and offer
          significant benefits for owner/customer teams. Discover how our
          comprehensive suite of tools can transform your project management
          processes, from model validation and clash detection to budget
          tracking and data visualization.
        </Typography>
        <Typography variant="h3" size={24}>
          Topics we'll cover:
        </Typography>
        <List>
          <li>Workflow optimization for design teams</li>
          <li>Enhancing productivity for contractor teams</li>
          <li>Support for consultant teams</li>
          <li>Benefits for owner/customer teams</li>
        </List>
        <div className={s.youtubeVideo} onClick={() => setModal(true)}>
          <GatsbyImage
            image={images.image1.childImageSharp.gatsbyImageData}
            alt="SIGNAX TOOLS. Turbocharge your BIM Projects. Workshop recording"
          />
        </div>
      </NewsSectionBlock>
      {modal && (
        <YoutubeAccessForm
          submitBtnText="Watch video"
          onClose={() => setModal(false)}
          title="Workshop recording access"
          titleVideo="WORKSHOP. SIGNAX TOOLS Turbocharge your BIM Projects"
          srcVideo="https://www.youtube.com/embed/h4qdBqLoeFU?si=DTJv2pOel7_e1Vl7"
        />
      )}
    </>
  )
}

const imagesQuery = graphql`
  query {
    image1: file(
      relativePath: {
        eq: "news/tools-turbocharge-workshop-recording/image-1.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 680, placeholder: BLURRED)
      }
    }
  }
`

export default ToolsTurbochargeWorkshop
